import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'HelloWorld',
            component: () => import('@/views/login.vue')
        },
        {
            path: '/IM',
            name: 'IM',
            component: () => import('@/views/IM.vue')
        }
    ]
})

export default router
