
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';
import * as api from "@/api/tran";//引入对应的api
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    fanyi(item: any) {
      api.trans({ query: item.text }).then((res) => {
        if (res.data.code == 1) {
          item.fy = res.data.data[0].dst
        }
      });
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {
        text: [{
          text: "",
          name: "",
          src: "",
          fy: ""
        }]
      },
    });

    watchEffect(() => {
      data.data = props.data;
    });
    return {
      ...toRefs(data),
    };
  },
});
