import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eca45d2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-box"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.text, (item, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      _createElementVNode("div", null, [
        (item.name === 'text')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createTextVNode(_toDisplayString(item.text) + " ", 1),
              _createElementVNode("span", {
                onClick: ($event: any) => (_ctx.fanyi(item)),
                class: "fy"
              }, null, 8, _hoisted_2)
            ]))
          : (item.name === 'img')
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "text-img",
                src: item.src
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
      ]),
      (item.fy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(item.fy), 1)
          ]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}