import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fbf287c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "face",
  id: "face"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "icon icon-face",
      title: "表情",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }),
    _withDirectives(_createElementVNode("main", {
      class: _normalizeClass(["face-main", [_ctx.isH5 && 'face-H5-main']]),
      ref: "dialog"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("ul", {
          class: "face-list",
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (childrenItem, childrenIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "face-list-item",
              key: childrenIndex,
              onClick: ($event: any) => (_ctx.select(childrenItem, childrenIndex))
            }, [
              (index === 0)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.emojiUrl + _ctx.emojiMap[childrenItem]
                  }, null, 8, _hoisted_3))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "face-img",
                    src: _ctx.faceUrl + childrenItem + '@2x.png'
                  }, null, 8, _hoisted_4))
            ], 8, _hoisted_2))
          }), 128))
        ])), [
          [_vShow, _ctx.currentIndex === index]
        ])
      }), 128))
    ], 2), [
      [_vShow, _ctx.show]
    ])
  ]))
}