
import { useTimeoutFn } from '@vueuse/core';
import { computed, CSSProperties, defineComponent, onMounted, ref, watch } from 'vue';
export default defineComponent({
  name: 'TUIMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 3000,
    },
    repeatNum: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      required: false,
    },
    offset: {
      type: Number,
      default: 20,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
    isH5: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const visible = ref(false);

    let stopTimer: (() => void) | undefined = undefined;

    function startTimer() {
      if (props.duration > 0) {
        ({ stop: stopTimer } = useTimeoutFn(() => {
          if (visible.value) close();
        }, props.duration));
      }
    }

    function clearTimer() {
      stopTimer?.();
    }

    function close() {
      visible.value = false;
    }

    watch(
      () => props.repeatNum,
      () => {
        clearTimer();
        startTimer();
      }
    );

    const customStyle = computed<CSSProperties>(() => ({
      top: `${props.offset}px`,
      zIndex: props.zIndex,
    }));

    onMounted(() => {
      startTimer();
      visible.value = true;
    });

    const handleStyle = (type?: string) => {
      if (type && (type === 'error' || type === 'success' || type === 'warning')) return type;
      return 'normal';
    };

    return {
      visible,
      customStyle,
      handleStyle,
    };
  },
});
