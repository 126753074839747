import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
const app = createApp(App);
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

export const sayHi = (userID1: any) => {
    const SDKAppID = 70000374; // Your SDKAppID
    const secretKey = '268ced1fbccf10ad877df3984bf0f1214d0cf437c926f5e8a25bfdccf5bbe306'; //Your secretKey
    const userID = userID1 + ''; // User ID
    const TUIKit = TUICore.init({
        SDKAppID,
    });
    TUIKit.use(TUIComponents);
    TUIKit.login({
        userID: userID,
        userSig: genTestUserSig({
            SDKAppID,
            secretKey,
            userID,
        }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
    });
    app.use(TUIKit);
}


app.provide('sayHi', sayHi)

// sayHi(window.localStorage.getItem("userID") || "")


// const SDKAppID = 70000020; // Your SDKAppID
//     const secretKey = '898ecf665a74d3b14506608fb82cc5dcbe422b7235aba7fb3a8028c460593726'; //Your secretKey
//     const userID = '20'; // User ID
//     const TUIKit = TUICore.init({
//         SDKAppID,
//     });
//     TUIKit.use(TUIComponents);
//     TUIKit.login({
//         userID: userID,
//         userSig: genTestUserSig({
//             SDKAppID,
//             secretKey,
//             userID,
//         }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
//     });
//     app.use(TUIKit);

import axios from 'axios'
app.provide('$axios', axios)

app.use(ElementPlus);

app.use(router);
app.mount('#app');